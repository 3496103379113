<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <v-text-field
          :key="refresh"
          :label="$t('labels.title')"
          outlined
          hide-details
          v-model="item.title"
        />
      </v-card-text>
    </v-card>

    <lms-btn-holder>
      <v-btn large depressed color="primary" @click="save">
        {{ $t('labels.save') }}
      </v-btn>
      <v-btn large depressed @click="back">
        {{ $t('labels.cancel') }}
      </v-btn>
    </lms-btn-holder>
  </div>
</template>

<script lang="js">
export default {
	data: () => ({
		refresh: 0,
	}),
	computed: {
		item(){
			return this.$store.state.setting.trainingType.view;
		}
  },
  methods: {
    async save() {
			await this.$store.dispatch(
				'setting/saveTrainingType'
			);
			this.back()
    },
		back(){
			this.$router.push({name:'settings-training-type-list'});
		}
  }
}
</script>
