<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <div class="system-warning" v-if="editing">
      {{ $t('labels.settings.dataWarning') }}
    </div>

    <f-container topBottom>
      <f-training-type-form />
    </f-container>
  </div>
</template>

<script lang="js">
import FTrainingTypeForm from '../../../components/views/settings/data/TrainingTypeForm.vue'


export default {
	components: {
		FTrainingTypeForm
	},
	data: () => ({
		editing: false
	}),
	computed: {
		prevLinks() {
      return [
        {
          title: this.$t("labels.settings"),
          route: {
            name: "settings"
          }
        },
        {
          title: this.$t("labels.trainingType"),
          route: {
            name: "settings-training-type-list"
          }
        }
      ];
    },
    title() {
      return this.$t("labels.trainingType");
    },
	},
	created(){
		if(this.$route.name == 'settings-training-type-edit') {
			this.editing = true

			this.get()
		} else {
			this.$store.commit('system/loading', false);
		}
	},
	methods: {
		async get(){
			const result = await this.$store.dispatch('setting/getTrainingType', this.$route.params.id)
			if(!result) {
				this.$router.push({
					name: 'settings-training-type-list'
				})
			}
		}
	}
}
</script>

<style lang="css" scoped>
.system-warning {
  padding: 30px;
  margin: 30px 40px -18px 40px;
  max-width: 1600px;
  height: 100%;
  position: relative;
  background-color: red;
  line-height: 1.8rem;
  font-size: 1.2rem;
  color: black;
}
</style>
